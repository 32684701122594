<template>
  <div v-if="appStateStore?.loadingPage" id="loading-overlay">
    <div class="content-loading">
      <img
        src="~/assets/svg/loading-ellipsis.svg"
        alt="loading"
        width="200"
        height="200"
        class="mx-auto"
      >
    </div>
  </div>
</template>

<script setup>
import { useAppStateStore } from '~/store/appState'
const appStateStore = useAppStateStore()
</script>

<style scoped>
#loading-overlay {
  background-color: rgba(16,16,16,0.56078);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1050;
}

.content-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>