<template>
  <div>
    <slot></slot>
    <LoadingGlobal />
  </div>
</template>

<script lang="ts" setup>
</script>

<style scoped>

</style>